<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Allocate Budget
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="checkValidation">
      <validation-observer
        ref="budgetAllocationCreateForm"
        #default="{invalid}"
      >
        <b-card
          class="px-3 py-2"
        >
          <b-row
            v-for="(item, index) in allocations"
            :id="'allocation' + index"
            :key="index"
          >
            <b-col cols="11">
              <validation-provider
                #default="{ errors }"
                name="Budgetary Period"
                vid="budgetaryPeriod"
                rules="required"
              >
                <b-form-group
                  label="Budgetary Period*"
                  label-for="h-budget-allocation-budgetaryPeriod"
                  label-cols-md="2"
                  :state="(errors.length > 0 || item.budgetaryPeriodValidation) ? false : null"
                >
                  <v-select
                    id="h-budget-allocation-budgetaryPeriod"
                    v-model="item.budgetaryPeriod"
                    label="name"
                    placeholder="Select from list"
                    :options="budgetaryPeriodOptions"
                    :reduce="title => title._id"
                    :clearable="false"
                    @input="submitOverviewOption(index); item.budgetaryPeriodValidation == true ? item.budgetaryPeriodValidation = false : null"
                  >
                    <template #option="{ name, start, end }">
                      <span class=""> {{ name }} ({{ dateFormat(start) }} - {{ dateFormat(end) }})</span>
                    </template>

                    <template #selected-option="{ name, start, end }">
                      <span class=""> {{ name }} ({{ dateFormat(start) }} - {{ dateFormat(end) }})</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="item.budgetaryPeriodValidation"
                    class="text-danger"
                  >
                    {{ item.budgetaryPeriodError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="1">
              <b-button
                variant="flat-primary"
                class="mt-delete-button primary-button-with-background no-background"
                type="button"
                @click="removeAllocation(index)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-25"
                />
              </b-button>
            </b-col>
            <b-col cols="md-11">
              <b-form-group
                label="Expense Type*"
                label-for="h-event-expenseType"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Expense Type"
                  vid="expenseType"
                  rules="required"
                >
                  <b-form-radio-group
                    id="h-event-expenseType"
                    v-model="item.expenseType"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                    @input="submitOverviewOption(index)"
                  >
                    <b-form-radio
                      v-for="(option, indx) in expenseTypeOptions"
                      :key="indx"
                      :value="option.value"
                    >
                      <feather-icon
                        v-if="item.expenseType === option.value"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ option.text }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="item.expenseTypeValidation"
                    class="text-danger"
                  >
                    {{ item.expenseTypeInvalidMessage }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="11">
              <validation-provider
                #default="{ errors }"
                name="Cost Centre"
                vid="costCenter"
                rules="required"
              >
                <b-form-group
                  label="Cost Centre*"
                  label-for="h-budget-allocation-costCenter"
                  label-cols-md="2"
                  :state="(errors.length > 0 || item.costCenterValidation) ? false : null"
                >
                  <v-select
                    id="h-budget-allocation-costCenter"
                    v-model="item.costCenter"
                    label="name"
                    placeholder="Select from list"
                    :options="costCenterComputation(index)"
                    :reduce="title => title._id"
                    :clearable="false"
                    @input="submitOverviewOption(index); item.costCenterValidation == true ? item.costCenterValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="item.costCenterValidation"
                    class="text-danger"
                  >
                    {{ item.costCenterError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="11">
              <validation-provider
                #default="{ errors }"
                name="Expense Category"
                vid="expenseCategoryType"
                rules="required"
              >
                <b-form-group
                  label-for="h-budget-allocation-expenseCategoryType"
                  label-cols-md="2"
                  :state="(errors.length > 0 || item.expenseCategoryTypeValidation) ? false : null"
                >
                  <template #label>
                    <span
                      class=""
                    >
                      Sub-budget*
                    </span>
                    <br>
                    <span>(Expense Category)</span>
                  </template>
                  <v-select
                    v-if="item.expenseType === 'Non-budgeted'"
                    id="h-budget-allocation-expenseCategoryType"
                    v-model="item.expenseCategoryType"
                    label="name"
                    placeholder="Select from list"
                    :options="item.expenseCategoryOptions"
                    :reduce="title => title._id"
                    :clearable="false"
                    @input="item.expenseCategoryTypeValidation == true ? item.expenseCategoryTypeValidation = false : null"
                  />
                  <v-select
                    v-else
                    id="h-budget-allocation-expenseCategoryType"
                    v-model="item.expenseCategoryType"
                    label="code"
                    placeholder="Select from list"
                    :options="item.expenseCategoryOptions"
                    :reduce="title => title._id"
                    :clearable="false"
                    @input="checkAmountValidation(index);item.expenseCategoryTypeValidation == true ? item.expenseCategoryTypeValidation = false : null"
                  >
                    <template #option="{ expenseCategoryName, code, remaining }">
                      <span class=""> {{ code || '-' }} - {{ expenseCategoryName }}</span>
                      <span
                        v-if="canViewThisAction('see-balance', 'BudgetOverview')"
                        class="text-bold-black"
                      >
                        <!-- eslint-disable -->
                         (S$ {{ numberFormat(remaining) }})
                        <!-- eslint-enable -->
                      </span>
                    </template>

                    <template #selected-option="{ expenseCategoryName, code, remaining }">
                      <span class=""> {{ code || '-' }} - {{ expenseCategoryName }}</span>
                      <span
                        v-if="canViewThisAction('see-balance', 'BudgetOverview')"
                        class="text-bold-black"
                        style="white-space: break-spaces;"
                      >
                        <!-- eslint-disable -->
                         (S$ {{ numberFormat(remaining) }})
                        <!-- eslint-enable -->
                      </span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="item.expenseCategoryTypeValidation"
                    class="text-danger"
                  >
                    {{ item.expenseCategoryTypeError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="md-11">
              <b-form-group
                label="Allocated Amount"
                label-for="h-event-allocatedAmount"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Allocated Amount"
                  vid="allocatedAmount"
                  rules=""
                >
                  <b-form-input
                    id="h-event-allocatedAmount"
                    v-model="item.allocatedAmount"
                    placeholder="S$"
                    :state="(errors.length > 0 || item.allocatedAmountValidation) ? false : null"
                    name="allocatedAmount"
                    @input="checkAmountValidation(index)"
                    @keypress="validateNumberInput"
                    @paste="validateNumberPaste"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="item.allocatedAmountValidation"
                    class="text-danger"
                  >
                    {{ item.allocatedAmountError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr class="dividerHR">
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="addNewAllocation()"
            >
              <span>Allocate Another Budget</span>
            </b-button>
            <b-button
              v-if="allocations.length > 1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              @click="deleteAllAllocation()"
            >
              <span>Delete All</span>
            </b-button>
          </div>
        </b-card>

        <b-card
          header-tag="header"
          class="attachment-section"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Attachments & Justifications
            </h3>
          </template>
          <b-col
            cols="12"
            :class="{ 'show-error': showFileUploadError }"
          >
            <vue-dropzone
              id="event-images"
              ref="myVueDropzone"
              :options="dropzoneOptions"
              :use-custom-slot="true"
              @vdropzone-files-added="processFile"
              @vdropzone-removed-file="fileRemoved"
              @vdropzone-error="handleError"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  Drop files here or click to upload
                </h3>
                <div class="subtitle">
                  The file should not be bigger than 15 mb. JPEG, PNG or PDF only.
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  class="mt-2"
                >
                  Upload file
                </b-button>
              </div>
            </vue-dropzone>
            <small
              v-if="showFileUploadError"
              class="text-danger mt-50"
            >
              You’re using Non-Budgeted for this spending and is required for attaching justification memo for review and approval purpose.
            </small>
          </b-col>
        </b-card>

        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'purchasing-purchase-request-show', params: { id: $route.params.id } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid || hasError || !allocations.length"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Allocate Budget</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BForm, BButton, BNav, BFormRadioGroup, BFormRadio, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BNav,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,

    vSelect,
    vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: store.state.auth.userData,
      allocations: [
        {
          budgetaryPeriod: '',
          expenseType: '',
          costCenter: '',
          expenseCategoryType: '',
          allocatedAmount: '',
          expenseCategoryOptions: [],
          budgetaryPeriodValidation: false,
          budgetaryPeriodError: '',
          expenseTypeValidation: false,
          expenseTypeError: '',
          costCenterValidation: false,
          costCenterError: '',
          expenseCategoryTypeValidation: false,
          expenseCategoryTypeError: '',
          allocatedAmountValidation: false,
          allocatedAmountError: '',
        },
      ],
      hasError: false,
      showFileUploadError: false,
      expenseType: '',
      attachments: [],
      expenseTypeOptions: [
        { text: 'Operating Expenses', value: 'Operating Expenses' },
        { text: 'CAPEX (Fixed Assets)', value: 'CAPEX (Fixed Assets)' },
        { text: 'CAPEX (Lease Assets)', value: 'CAPEX (Lease Assets)' },
        { text: 'Projects', value: 'Projects' },
        { text: 'Non-budgeted', value: 'Non-budgeted' },
      ],
      budgetaryPeriodOptions: [],
      costCenterOptions: [],
      expenseCategoryOptions: [],
      budgets: [],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 15,
        addRemoveLinks: true,
        // maxFiles: 1,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
      },

      // validation rules
      required,
    }
  },
  created() {
    const expType = this.$route.query.type
    if (expType) {
      if (expType === 'Purchase Requisition Form (PRF)') {
        this.expenseType = 'Operating Expenses'
      } else {
        this.expenseType = 'CAPEX (Fixed Assets)'
      }
      this.allocations[0].expenseType = this.expenseType
    } else {
      this.expenseType = 'Operating Expenses'
    }
    this.$http.get('budget/budget/cost-center-period-options', { params: { department: true } })
      .then(response => {
        this.budgetaryPeriodOptions = response.data.budgetPeriods || []
        this.budgets = response.data.budgets || []
        this.costCenterOptions = response.data.costCenters || []
        this.expenseCategoryOptions = response.data.expenseCategories || []
        this.allocations[0].budgetaryPeriod = this.budgetaryPeriodOptions.length ? this.budgetaryPeriodOptions[0]._id : ''
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    canViewThisAction,
    costCenterComputation(index) {
      if (this.allocations[index].expenseType === 'Non-budgeted') {
        return this.costCenterOptions
      }
      const filtered = this.budgets.filter(b => b.budgetPeriod === this.allocations[index].budgetaryPeriod)
      const ids = [...new Set(filtered.map(item => item.costCenter))]
      if (ids.length) {
        return this.costCenterOptions.filter(b => ids.includes(b._id))
      }
      return []
    },
    expenseCategoryComputation(index) {
      console.log(index)
      return this.expenseCategoryOptions
    },
    validateNumberInput(event) {
      if (event.key && !/^-?\d*[.]?\d*$/.test(event.key)) {
        event.preventDefault()
      }
    },
    validateNumberPaste(event) {
      const text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (text && !/^-?\d*[.]?\d*$/.test(text)) {
        event.preventDefault()
      }
    },
    checkAmountValidation(index) {
      if (this.allocations[index].expenseType === 'Non-budgeted') {
        this.allocations[index].expenseCategoryTypeValidation = false
        this.hasError = false
      } else {
        const option = this.allocations[index].expenseCategoryOptions.find(o => o._id === this.allocations[index].expenseCategoryType)
        if (option) {
          if (this.allocations[index].allocatedAmount) {
            if (option.remaining < this.allocations[index].allocatedAmount) {
              this.allocations[index].expenseCategoryTypeError = 'The selected budget balance is not enough'
              this.allocations[index].expenseCategoryTypeValidation = true
              this.hasError = true
            } else {
              this.allocations[index].expenseCategoryTypeError = ''
              this.allocations[index].expenseCategoryTypeValidation = false
              this.hasError = false
            }
          } else {
            this.allocations[index].expenseCategoryTypeError = ''
            this.allocations[index].expenseCategoryTypeValidation = false
            this.hasError = false
          }
        } else {
          this.allocations[index].expenseCategoryTypeError = 'Select the expense category first.'
          this.allocations[index].expenseCategoryTypeValidation = true
          this.hasError = true
        }
      }
    },
    submitOverviewOption(index) {
      const objectsEqual = (o1, o2) => (o1._id === o2._id)
      const arraysEqual = (a1, a2) => a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))
      // eslint-disable-next-line eqeqeq
      if (this.allocations[index].costCenter != '' && this.allocations[index].budgetaryPeriod != '' && this.allocations[index].expenseType != '') {
        if (this.allocations[index].expenseType === 'Non-budgeted') {
          this.allocations[index].expenseCategoryType = ''
          this.allocations[index].expenseCategoryOptions = this.expenseCategoryOptions.filter(i => i.type === 'Non-budgeted')
        } else {
          // eslint-disable-next-line no-lonely-if
          if (this.costCenterComputation(index).length && this.costCenterComputation(index).find(o => o._id === this.allocations[index].costCenter)) {
            const formData = new FormData()
            formData.append('costCenter', this.allocations[index].costCenter)
            formData.append('budgetPeriod', this.allocations[index].budgetaryPeriod)
            formData.append('expenseCategoryType', this.allocations[index].expenseType)
            this.$http.post('budget/budget/find', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                const { budgetItems } = response.data
                // const uniq = (arr, track = new Set()) => arr.filter(({ expenseCategory }) => (track.has(expenseCategory) ? false : track.add(expenseCategory)))
                const map = new Map(budgetItems.map(pos => [pos.expenseCategory, pos]))
                const uniques = [...map.values()]
                // const ids = [...new Set(budgetItems.map(item => item.expenseCategory))]
                // this.allocations[index].expenseCategoryOptions = this.costCenterOptions.filter(b => ids.includes(b._id))
                if (arraysEqual(this.allocations[index].expenseCategoryOptions, uniques) === false) {
                  const departmentArray = this.user.department.map(({ _id }) => _id)
                  const departmentFiltered = uniques.filter(i => {
                    const filtered = i.expenseCategory.departments.filter(d => departmentArray.includes(d))
                    return filtered.length
                  })
                  this.allocations[index].expenseCategoryType = ''
                  this.allocations[index].expenseCategoryOptions = departmentFiltered
                }
              })
              .catch(error => {
                this.allocations[index].expenseCategoryType = ''
                this.allocations[index].expenseCategoryOptions = []
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          } else {
            this.allocations[index].costCenter = ''
            this.allocations[index].expenseCategoryType = ''
            this.allocations[index].expenseCategoryOptions = []
          }
        }
      }
    },
    processFile(file) {
      this.showFileUploadError = false
      const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size <= 15 * 1024 * 1024) {
            const exist = this.attachments.find(o => o.name === element.name && o.size === (element.size / (1024 * 1024)).toFixed(2))
            if (exist) {
              setTimeout(() => {
                if (element.previewElement) {
                  element.previewElement.remove()
                }
              }, 500)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Duplicate File',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.description = ''
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          }
        }
      })
    },
    fileRemoved(file) {
      if (file.dataURL) {
        this.attachments = this.attachments.filter(element => element.data !== file.dataURL)
      } else {
        this.attachments = this.attachments.filter(element => element.name !== file.name)
      }

      const previewLength = document.getElementsByClassName('dz-preview').length
      if (!previewLength) {
        this.$refs.myVueDropzone.removeAllFiles()
      }
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    deleteAllAllocation() {
      this.allocations = []
    },
    addNewAllocation() {
      this.allocations.push({
        budgetaryPeriod: this.budgetaryPeriodOptions.length ? this.budgetaryPeriodOptions[0]._id : '',
        expenseType: this.expenseType,
        costCenter: '',
        expenseCategoryType: '',
        allocatedAmount: '',
        expenseCategoryOptions: [],
        budgetaryPeriodValidation: false,
        budgetaryPeriodError: '',
        expenseTypeValidation: false,
        expenseTypeError: '',
        costCenterValidation: false,
        costCenterError: '',
        expenseCategoryTypeValidation: false,
        expenseCategoryTypeError: '',
        allocatedAmountValidation: false,
        allocatedAmountError: '',
      })
    },
    removeAllocation(index) {
      if (this.allocations.length > 1) {
        this.allocations.splice(index, 1)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one row.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
    },
    checkValidation() {
      this.$refs.budgetAllocationCreateForm.validate().then(success => {
        if (success) {
          const nonBudgetItems = this.allocations.filter(i => i.expenseType === 'Non-budgeted')
          if (nonBudgetItems.length) {
            if (this.attachments.length) {
              this.submitForm()
            } else {
              this.showFileUploadError = true
              const items = document.getElementsByClassName('attachment-section')
              if (items && items.length) {
                items[0].scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'center',
                })
              }
            }
          } else {
            this.submitForm()
          }
        }
      })
    },
    submitForm() {
      this.$refs.budgetAllocationCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          const allocatedItems = []
          let total = 0
          this.allocations.forEach(allocation => {
            const budgetaryPeriodData = this.budgetaryPeriodOptions.find(o => o._id === allocation.budgetaryPeriod)
            const budgetPeriodString = `${budgetaryPeriodData.name} (${this.dateFormat(budgetaryPeriodData.start)} - ${this.dateFormat(budgetaryPeriodData.end)})`
            const costCenterData = this.costCenterOptions.find(o => o._id === allocation.costCenter)
            const costCenterString = costCenterData.name
            if (allocation.expenseType === 'Non-budgeted') {
              const subBudget = allocation.expenseCategoryOptions.find(o => o._id === allocation.expenseCategoryType)
              const obj = {
                budgetaryPeriod: allocation.budgetaryPeriod,
                budgetPeriodString,
                costCenterString,
                subBudgetString: `${subBudget.code} - ${subBudget.name}`,
                expenseType: allocation.expenseType,
                costCenter: allocation.costCenter,
                expenseCategoryType: allocation.expenseCategoryType,
                allocatedAmount: allocation.allocatedAmount ? this.floatNumber(allocation.allocatedAmount) : 0,
              }
              allocatedItems.push(obj)
            } else {
              const subBudget = allocation.expenseCategoryOptions.find(o => o._id === allocation.expenseCategoryType)
              const obj = {
                budgetaryPeriod: allocation.budgetaryPeriod,
                budgetPeriodString,
                costCenterString,
                subBudgetString: `${subBudget.code} - ${subBudget.expenseCategoryName} (S$ ${this.numberFormat(subBudget.remaining)})`,
                expenseType: allocation.expenseType,
                costCenter: allocation.costCenter,
                expenseCategoryType: subBudget.expenseCategory,
                budget: subBudget.budget,
                subBudget: subBudget._id,
                allocatedAmount: allocation.allocatedAmount ? this.floatNumber(allocation.allocatedAmount) : 0,
              }
              allocatedItems.push(obj)
            }
            total += Number(allocation.allocatedAmount ?? 0)
          })

          formData.append('allocations', JSON.stringify(allocatedItems))
          formData.append('attachments', JSON.stringify(this.attachments))
          formData.append('purchaseRequest', this.$route.params.id)
          formData.append('department', this.$route.query.department)
          formData.append('total', total.toFixed(2))

          this.$http.post('budget/budget/allocation/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Budget Allocated.',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.id } })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: validationError.msg || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

  <style scoped>
    .removeText {
      color:#D91B35;
      cursor: pointer;
    }
    .addOptionText {
      color: #104D9D;
      cursor: pointer;
    }
    .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
      border-right-width: 1px !important;
    }
    .inlineElement {
      display: flex;
    }
  </style>
